@use '@/scss/underscore' as _;

.wrapper {
  @include _.full-page;
}

.container {
  @include _.full-width;
  margin-top: _.unit(2);
}


.title {
  @include _.title;
}

:global(body.mobile) {
  .container {
    margin-top: _.unit(2);
  }

  .title {
    margin-bottom: _.unit(6);
  }
}

:global(body.desktop) {
  .container {
    margin-top: _.unit(12);
  }

  .title {
    font: var(--font-title-medium);
    margin-bottom: _.unit(4);
  }
}
