@use '@/scss/underscore' as _;

.form {
  @include _.flex-column;

  > * {
    width: 100%;
  }

  .inputField {
    margin-bottom: _.unit(4);
  }

  .formFields {
    margin-bottom: _.unit(8);
  }

  .terms {
    margin-bottom: _.unit(4);
  }

  .formErrors {
    margin-top: _.unit(-2);
    margin-bottom: _.unit(4);
  }
}

:global(body.desktop) {
  .formFields {
    margin-bottom: _.unit(2);
  }
}
