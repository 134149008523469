@use '@/scss/underscore' as _;

.wrapper {
  @include _.full-page;
}

.container {
  @include _.full-width;
  margin-top: _.unit(2);
}

.title {
  @include _.title;
  margin-bottom: _.unit(1);
}

.detail {
  margin-bottom: _.unit(6);
  color: var(--color-caption);
}

:global(body.mobile) {
  .container {
    margin-top: _.unit(2);
  }

  .detail {
    font: var(--font-body);
  }
}

:global(body.desktop) {
  .container {
    margin-top: _.unit(12);
  }

  .title {
    font: var(--font-title-medium);
  }

  .detail {
    font: var(--font-caption);
  }
}
