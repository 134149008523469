@use '@/scss/underscore' as _;

.form {
  @include _.flex-column;

  > * {
    width: 100%;
  }

  .inputField,
  .terms,
  .switch {
    margin-bottom: _.unit(4);
  }

  .switch {
    margin-top: _.unit(-1);
    display: block;
  }

  .formFields {
    margin-bottom: _.unit(8);
  }
}

:global(body.desktop) {
  .formFields {
    margin-bottom: _.unit(2);
  }
}
