.overlay {
  position: fixed;
  background: var(--color-overlay);
  inset: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.ReactModal__Body--open) {
  overflow: hidden;
}
